import { AppProps } from 'app/App';
import { Container } from 'components/styled-components';
import { FC } from 'react';
import styled from 'styled-components';
import { About } from './About';
import { Contacts } from './Contacts';
import { Resume } from './Resume';
import { Services } from './Services';
import { Skills } from './Skills';
import { Testmonial } from './Testmonial';

const ContainerModified = styled.div`
  padding: 90px 10px 90px;
  position: relative;
  :nth-child(odd) {
    background: white;
  }
  :nth-child(even) {
    background: #fafbfc;
  }
`;
export const HomePage: FC<AppProps> = ({ sectionRefs }) => {
  return (
    <>
      <ContainerModified ref={sectionRefs[1]}>
        <Container>
          <About />
        </Container>
      </ContainerModified>
      <ContainerModified ref={sectionRefs[2]}>
        <Container>
          <Services />
        </Container>
      </ContainerModified>
      <ContainerModified>
        <Container>
          <Skills />
        </Container>
      </ContainerModified>
      <ContainerModified ref={sectionRefs[3]}>
        <Container>
          <Resume />
        </Container>
      </ContainerModified>
      <ContainerModified>
        <Container>
          <Testmonial />
        </Container>
      </ContainerModified>
      <ContainerModified ref={sectionRefs[4]}>
        <Container>
          <Contacts />
        </Container>
      </ContainerModified>
    </>
  );
};
