import { HomePage } from 'pages/Home/index';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppProps } from './App';
import { routes } from './constant';

export const AppRoutes: FC<AppProps> = ({ sectionRefs }) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={routes.home} />} />
      <Route path={routes.home}>
        <Route path={routes.home} element={<HomePage sectionRefs={sectionRefs} />} />
        <Route path="*" element={<Navigate to={routes.home} />} />
      </Route>
      <Route path="*" element={<Navigate to={routes.home} />} />
    </Routes>
  );
};
