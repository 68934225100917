import ChevronJpeg from 'assets/chevron.jpeg';
import DanskeBankJpeg from 'assets/danskebank.jpeg';
import ElsisProJpeg from 'assets/elsispro.jpeg';
import EntitySvg from 'assets/entity.svg';
import GoodTillJpeg from 'assets/goodtill.jpeg';
import InspiratiaJpeg from 'assets/inspiratia.jpeg';
import LaunchmenPng from 'assets/launchmen.png';
import OmegaPng from 'assets/omega.png';
import SebJpeg from 'assets/seb.jpg';
import StrongPointJpeg from 'assets/strongpoint.jpeg';
import VaultSpeedJpeg from 'assets/vaultspeed.jpeg';
import VertabloxJpeg from 'assets/vertablox.jpeg';
import { Card, CardContent, SectionBlock, SectionTitle, SimpleText, Spacer } from 'components/styled-components';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const CardBlock = styled.div<{ isMobile: boolean }>`
  margin: auto;
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  height: 100%;
`;
const CardModified = styled(Card)`
  width: 40%;
  min-width: 250px;
  min-height: 200px;
`;
const ChipsWrapper = styled.div`
  margin: auto;
  align-items: end;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
const Chips = styled.span`
  background-color: #f5f5ff;
  font-size: 14px;
  font-family: Roboto;
  color: #2662fa;
  letter-spacing: 1px;
  padding: 10px;
  border-radius: 20px;
  display: block;
  margin-bottom: 15px;
`;
const ImgPlacholder = styled.div<{ isMobile: boolean }>`
  margin: ${props => (props.isMobile ? 'auto' : 'unset')};
  display: flex;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-color: #fafafc;
  border: 1px solid #c8c8e7;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;
const ImgTextBlock = styled.div`
  margin: auto;
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
const DateText = styled(SimpleText)`
  color: #2662fa;
`;
const NameText = styled(SimpleText)`
  font-size: 24px;
  font-family: Roboto;
  color: #272728;
  letter-spacing: 1px;
  font-weight: bold;
  margin-bottom: 15px;
`;
const EntityImg = styled.img`
  display: block;
  width: 80px;
  height: 80px;
`;

export const Resume: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <>
      <SectionBlock>
        <SectionTitle>Roles and Projects i have been part of</SectionTitle>
        <CardBlock isMobile={isTabletOrMobile}>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Self-employed</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={EntitySvg} alt="Personal Projects" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Jan 2024 - Present</DateText>
                <NameText>Personal Projects</NameText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Hybrid, Employment</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={VertabloxJpeg} alt="UAB Vertablox" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Feb 2025 - Present</DateText>
                <NameText>UAB Vertablox</NameText>
                <SimpleText>Senior Fullstack Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={ChevronJpeg} alt="Chevron Corporation" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Dec 2022 - Present</DateText>
                <NameText>Chevron Corporation</NameText>
                <SimpleText>Application Engineer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={SebJpeg} alt="SEB Bank" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Aug 2024 - Feb 2025</DateText>
                <NameText>SEB Bank</NameText>
                <SimpleText>Senior Frontend Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Hybrid, Employment</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={StrongPointJpeg} alt="UAB StrongPoint" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Feb 2024 - May 2024</DateText>
                <NameText>UAB StrongPoint</NameText>
                <SimpleText>Lead Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={DanskeBankJpeg} alt="Danske Bank" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Feb 2021 - Dec 2023</DateText>
                <NameText>Danske Bank</NameText>
                <SimpleText>Senior Frontend Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={VaultSpeedJpeg} alt="VaultSpeed" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Nov 2020 - Feb 2023</DateText>
                <NameText>VaultSpeed</NameText>
                <SimpleText>Lead Frontend Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={VaultSpeedJpeg} alt="VaultSpeed" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Aug 2019 - Nov 2020</DateText>
                <NameText>VaultSpeed</NameText>
                <SimpleText>Fullstack Javascript Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={ElsisProJpeg} alt="Elsis Pro" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Aug 2020 - Apr 2021</DateText>
                <NameText>Elsis Pro</NameText>
                <SimpleText>Fullstack Javascript Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={GoodTillJpeg} alt="The Good Till Co" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Jun 2018 - July 2019</DateText>
                <NameText>The Good Till Co</NameText>
                <SimpleText>App Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={EntitySvg} alt="PCByVoice LTD" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Aug 2018 - Feb 2019</DateText>
                <NameText>PCByVoice LTD</NameText>
                <SimpleText>Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={InspiratiaJpeg} alt="STMCB Limited t/a Inspiratia" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Mar 2018 - Aug 2018</DateText>
                <NameText>STMCB Limited t/a Inspiratia</NameText>
                <SimpleText>Fullstack .NET Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Employment, Onsite</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={OmegaPng} alt="UAB Omega Technology" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Mar 2013 - Jun 2018</DateText>
                <NameText>UAB Omega Technology</NameText>
                <SimpleText>Software Systems Engineer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Employment, Onsite</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={OmegaPng} alt="UAB Omega Technology" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Jun 2012 - Aug 2012</DateText>
                <NameText>UAB Omega Technology</NameText>
                <SimpleText>The Trainee Programmer / Tester</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Remote, Contract</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={LaunchmenPng} alt="Launchmen" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Jan 2015 - Feb 2015</DateText>
                <NameText>LaunchMen</NameText>
                <SimpleText>Web Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
          <CardModified border={'1px solid #D8D8EC'}>
            <ChipsWrapper>
              <Spacer />
              <Chips>Employment, Onsite</Chips>
            </ChipsWrapper>
            <CardContent isMobile={isTabletOrMobile}>
              <ImgPlacholder isMobile={isTabletOrMobile}>
                <EntityImg src={EntitySvg} alt="VŠĮ Technology and Business School" />
              </ImgPlacholder>
              <ImgTextBlock>
                <DateText>Sep 2012 - Sep 2012</DateText>
                <NameText>VŠĮ Technology and Business School</NameText>
                <SimpleText>Web Developer</SimpleText>
              </ImgTextBlock>
              <Spacer />
            </CardContent>
          </CardModified>
        </CardBlock>
      </SectionBlock>
    </>
  );
};
