import BeSvg from 'assets/be.svg';
import FeSvg from 'assets/fe.svg';
import FullstackSvg from 'assets/fullstack.svg';
import MobileSvg from 'assets/mobile.svg';
import { Card, CardContent, SectionBlock, SectionTitle, SimpleText } from 'components/styled-components';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const CardTitleBlock = styled.div<{ isMobile: boolean }>`
  text-align: left;
  min-width: ${props => (props.isMobile ? 'unset' : '300px')};
  margin-bottom: ${props => (props.isMobile ? '30px' : '0')};
`;
const Img = styled.img`
  display: block;
  margin-bottom: 10px;
`;
const CardTitle = styled.span`
  font-family: Roboto;
  font-size: 24px;
  color: black;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const Services: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <>
      <SectionBlock>
        <SectionTitle>What I Do</SectionTitle>
        <Card border={'unset'}>
          <CardContent isMobile={isTabletOrMobile}>
            <CardTitleBlock isMobile={isTabletOrMobile}>
              <Img src={FeSvg} alt="Frontend" />
              <CardTitle>Frontend Development</CardTitle>
            </CardTitleBlock>
            <SimpleText>
              I am capable to develop solutions with any modern frontend framework. Most knowledge i have with Angular,
              Vue, Knockout, React.
            </SimpleText>
          </CardContent>
        </Card>
        <Card border={'unset'}>
          <CardContent isMobile={isTabletOrMobile}>
            <CardTitleBlock isMobile={isTabletOrMobile}>
              <Img src={BeSvg} alt="Frontend" />
              <CardTitle>Backend Development</CardTitle>
            </CardTitleBlock>
            <SimpleText>
              I am capable to develop BE solutions with Microsoft stack, PHP, Java, Python or Node. However i am not
              limiting myself to only these technologies.
            </SimpleText>
          </CardContent>
        </Card>
        <Card border={'unset'}>
          <CardContent isMobile={isTabletOrMobile}>
            <CardTitleBlock isMobile={isTabletOrMobile}>
              <Img src={FullstackSvg} alt="Frontend" />
              <CardTitle>Fullstack Development</CardTitle>
            </CardTitleBlock>
            <SimpleText>
              My whole career i worked on both sides BE and FE. Therefore i am not limited to only FE or BE. I could
              tackle both sides.
            </SimpleText>
          </CardContent>
        </Card>
        <Card border={'unset'}>
          <CardContent isMobile={isTabletOrMobile}>
            <CardTitleBlock isMobile={isTabletOrMobile}>
              <Img src={MobileSvg} alt="Frontend" />
              <CardTitle>Mobile Development</CardTitle>
            </CardTitleBlock>
            <SimpleText>
              I worked on multiple mobile applications durring my career. Most knowleged i have with Ionic, Xamarin.
            </SimpleText>
          </CardContent>
        </Card>
      </SectionBlock>
    </>
  );
};
