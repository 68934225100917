import { FC, useState } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  margin: 0px 2px;
  padding: 0.65rem 0.9rem;
  border: unset;
`;
const Icon = styled.img`
  width: 40px;
  :hover {
    -webkit-filter: drop-shadow(1px 2px 25px rgba(19, 37, 70, 0.9));
    filter: drop-shadow(1px 2px 25px rgba(19, 37, 70, 0.9));
    cursor: pointer;
  }
  :active {
    -webkit-filter: drop-shadow(1px 2px 25px rgba(19, 37, 70, 0.9));
    filter: drop-shadow(1px 2px 25px rgba(19, 37, 70, 0.9));
    cursor: pointer;
  }
`;
interface Props {
  alt: string;
  src: string;
  srcHover: string;
  onClick: () => void;
}
export const IconButton: FC<Props> = ({ alt, src, srcHover, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ButtonWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      {!isHovered && <Icon src={src} alt={alt} />}
      {isHovered && <Icon src={srcHover} alt={alt} />}
    </ButtonWrapper>
  );
};
