import { FC } from 'react';
import { Puff } from 'react-loader-spinner';
import styled from 'styled-components';

const ButtonWrapper = styled.button<{ colorType: ColorType; isDisabled: boolean }>`
  cursor: pointer;
  background-color: ${props => (props.colorType === ColorType.primary ? '#daed1a' : '#2662FA')};
  border-radius: 5px;
  letter-spacing: 1px;
  margin: 0px 2px;
  font-size: 14px;
  font-weight: bold;
  padding: 0.65rem 0.9rem;
  min-width: 160px;
  color: ${props => (props.colorType === ColorType.primary ? 'black' : 'white')};
  border: 1px solid ${props => (props.colorType === ColorType.primary ? '#daed1a' : '#2662FA')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'inherit')};
  opacity: ${props => (props.isDisabled ? 0.6 : 1)};
  :hover {
    background-color: ${props => (props.colorType === ColorType.primary ? '#a7ba00' : '#002FC7')};
    border: 1px solid ${props => (props.colorType === ColorType.primary ? '#a7ba00' : '#002FC7')};
  }
  :active {
    background-color: ${props => (props.colorType === ColorType.primary ? '#8ea100' : '#0016AE')};
    border: 1px solid ${props => (props.colorType === ColorType.primary ? '#8ea100' : '#0016AE')};
  }
`;

const ButtonText = styled.span`
  vertical-align: super;
`;
const Icon = styled.img`
  width: 20px;
`;

export enum ColorType {
  primary,
  secondary
}
interface Props {
  colorType?: ColorType;
  isSubmit?: boolean;
  isLoading?: boolean;
  label: string;
  alt?: string;
  src?: string;
  onClick?: () => void;
}
export const Button: FC<Props> = ({
  label,
  alt,
  src,
  onClick,
  isSubmit = false,
  colorType = ColorType.primary,
  isLoading = false
}) => {
  return (
    <ButtonWrapper
      isDisabled={isLoading}
      disabled={isLoading}
      colorType={colorType}
      type={isSubmit ? 'submit' : 'button'}
      onClick={() => {
        if (typeof onClick === 'function' && !isLoading) {
          onClick();
        }
      }}
    >
      {isLoading && (
        <Puff
          height="20"
          width="20"
          color={colorType === ColorType.primary ? '#8ea100' : '#0016AE'}
          wrapperStyle={{ display: 'inline-block' }}
          ariaLabel="puff-loading"
        />
      )}
      {src && !isLoading && <Icon src={src} alt={alt} />}
      <ButtonText>{label}</ButtonText>
    </ButtonWrapper>
  );
};
