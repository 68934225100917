import AnimeshJpeg from 'assets/animesh.jpeg';
import ArturJpeg from 'assets/artur.jpeg';
import DirkJpeg from 'assets/dirk.jpeg';
import JovitaJpeg from 'assets/jovita.jpeg';
import LindsayJpeg from 'assets/lindsay.jpeg';
import NunoJpeg from 'assets/nuno.jpeg';
import QuotesSvg from 'assets/quotes.svg';
import RaviJpeg from 'assets/ravi.jpeg';
import SteveJpeg from 'assets/steve.jpeg';
import ZilvinasJpeg from 'assets/zilvinas.jpeg';
import { Card, SectionBlock, SectionTitle, SimpleText } from 'components/styled-components';
import { FC } from 'react';
import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Img = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 30px;
`;
const SimpleTextModified = styled(SimpleText)`
  font-size: 12px;
`;
const SimpleTextModifiedName = styled(SimpleText)`
  font-size: 16px;
  font-weight: 600;
  color: black;
`;
const Separator = styled.div`
  display: block;
  margin: auto;
  width: 30px;
  height: 2px;
  background-color: #adadad;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const PersonImg = styled.img`
  display: block;
  margin: auto;
  border-radius: 60px;
  margin-bottom: 10px;
`;

export const Testmonial: FC = () => {
  return (
    <>
      <SectionBlock>
        <SectionTitle>What People Say About Me</SectionTitle>
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true
          }}
          modules={[Autoplay, Pagination]}
        >
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                Evaldas proved to be a very good developer and a colleague. Not only his technical skills but also good
                &quot;feel&quot; of the team helped us a lot.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={ArturJpeg} alt="Arthur Radkiewicz" />
              <SimpleTextModifiedName>Artur Radkiewicz</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                As a member of my product development team, Evaldas has demonstrated to be an exceptional member of the
                team and has brought a refreshing perspective to our solution design. He has added significant business
                value in the design, build, test and implementation stages of our software development of a
                significantly complex enterprise solution. Evaldas is very skilled in angular UI development and plays a
                pivotal role on a high performing team to develop and implement new features to enhance the overall user
                experience of our product. I’m extremely fortunate to have someone of Evaldas skill set on my team, and
                highly recommend him as an exceptional software engineer who can work well with enterprise-level
                platform architectures and contribute features that enable key business workflows.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={SteveJpeg} alt="Steve Huerta" />
              <SimpleTextModifiedName>Steve Huerta</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                I had a pleasure to work with Evaldas in Vaultspeed for 3,5 years. He is a real volcano as a
                professional and as a person. Always full of ideas, propositions and motivation. Evaldas is not afraid
                of challenges and feels happy to dive into new technologies. He was our Lead Frontend developer who
                created a great new interface for our product and a real technical mentor to others. Also Evaldas is
                very responsible, open and funny person, real team player.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={JovitaJpeg} alt="Jovita Dirvanauskaitė" />
              <SimpleTextModifiedName>Jovita Dirvanauskaitė</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                I have managed Evaldas for 3,5 years now, and I can only say that I value him a lot as a person as well
                as a great mentor & developer. As the lead developer, he was the driving force behind our
                industry-standard interface and a technical mentor for the other front-end developers on our team. His
                enthusiasm, energy, motivation, speed & quality of delivery are for me his strongest points.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={DirkJpeg} alt="Dirk Vermeiren" />
              <SimpleTextModifiedName>Dirk Vermeiren</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                I&apos;ve been working with Evaldas for the past year and couldn&apos;t have better opinion about him -
                Evaldas is an excellent person and professional. During this period Evaldas has proved multiple times
                his competence and seniority within his role always acting and performing on high level bringing the
                best outcome to our business and our team. I recall the various video call with shared screens where
                Evaldas had help me and the team to pin point details, troubleshoot issues and quick turn around bugs
                into fixes, ideas into new features and problems into solutions. Talking more into details its easy to
                remind about many FE initiatives lead by Evaldas and successful achieved - for example we are just to
                steps away to release the full new version of our product interface, an amazing revamp and redesigned
                work done by Evaldas and his team mate. Evaldas is not only a Lead Developer shinning within his
                technical tasks but he is also having the needed touch and sense to understand and deal with people.
                I&apos;m truly thankful to have the opportunity to work with such a professional as Evaldas and I&apos;m
                always looking forward to be in touch, doing things together and see him growing and growing
                accomplishing his targets and reaching his goals. Thanks very much Evaldas for all your input, all the
                teachings you been sharing with us and all the nice moments spend together - I&apos;ve no doubt that
                from you only one outcome is possible, the success! Best wishes!
              </SimpleTextModified>
              <Separator />
              <PersonImg src={NunoJpeg} alt="Nuno Santos" />
              <SimpleTextModifiedName>Nuno Santos</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                Having worked with Evaldas for a little more than a year, I must say he is an excellent professional.
                Evaldas is an exceptional developer who possesses all the skills one would want in an excellent
                front-end software developer. He has been a great resource to my company. He did an incredible job on
                the Requirement Management project, making timely deliveries and helping the company deliver on our
                strategic visions. His work is always top-notch, and he is always welcoming to feedback and making
                improvements. Plus, Evaldas is self-motivated and a great team player.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={RaviJpeg} alt="Ravi Sejling" />
              <SimpleTextModifiedName>Ravi Sejling</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                Evaldas is super “responsive” developer. I also appreciate that he is very proactive and really a Team
                player, at the same time Evaldas is able to analyze, understand problems and make solution suggestions.
                Excellent communication with other developers within the project!
              </SimpleTextModified>
              <Separator />
              <PersonImg src={ZilvinasJpeg} alt="Žilvinas Grigonis" />
              <SimpleTextModifiedName>Žilvinas Grigonis</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                Evaldas is a hardworking and diligent developer. Always keen to take feedback, take on new challenges
                and work with the team to achieve best results for everyone. Its a pleasure to work with Evaldas.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={AnimeshJpeg} alt="Animesh Chowdhury" />
              <SimpleTextModifiedName>Animesh Chowdhury</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card border={'1px solid #c8c8e7'}>
              <Img src={QuotesSvg} alt="Quotes" />
              <SimpleTextModified>
                We had the pleasure of working with Evaldas for approximately six months in 2018. Evaldas carried out
                several pieces of work for us including JavaScript coding and C# coding. We were most impressed by his
                output, adaptability, ability to work on his own unattended and his skill in developing innovative
                solutions to problems we posed. We would have no second thoughts about employing Evaldas for future work
                with our company.
              </SimpleTextModified>
              <Separator />
              <PersonImg src={LindsayJpeg} alt="Lindsay Adam" />
              <SimpleTextModifiedName>Lindsay Adam</SimpleTextModifiedName>
            </Card>
          </SwiperSlide>
        </Swiper>
      </SectionBlock>
    </>
  );
};
