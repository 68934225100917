import emailjs from '@emailjs/browser';
import LocationSvg from 'assets/location.svg';
import MessageSvg from 'assets/message.svg';
import { Button, ColorType } from 'components/Button';
import { CardContent, SectionBlock, SectionTitle, SimpleText } from 'components/styled-components';
import { FC, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import styled from 'styled-components';

export const FormInfo = styled.div`
  margin: auto;
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
export const ContactInfo = styled.div`
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  height: 100%;
  flex-direction: row;
  gap: 20px;
`;
const ImgPlacholder = styled.div`
  display: flex;
  width: 60px;
  min-width: 60px;
  height: 60px;
  background-color: #2662fa;
  border-radius: 60px;
  align-items: center;
  justify-content: center;
`;
const TextBlock = styled.div`
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
`;
const SimpleTextModified = styled(SimpleText)`
  font-weight: 600;
`;
const SectionTitleModfied = styled(SectionTitle)`
  margin: 0;
  margin-bottom: 20px;
`;
const ContactSpacer = styled.div`
  margin-top: 40px;
`;
const Separator = styled.div`
  display: block;
  margin: auto;
  width: 100%;
  height: 2px;
  background-color: #ccd7f2;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const Img = styled.img`
  display: block;
  margin: auto;
`;
const ImgPlacholderClickable = styled(ImgPlacholder)`
  cursor: pointer;
`;
const Form = styled.form<{ isMobile?: boolean }>`
  border: 1px solid #2662fa;
  border-radius: 30px;
  width: ${props => (props.isMobile ? 'calc(100% - 40px)' : '45%')};
  padding: 40px 20px 40px 20px;
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
`;
const FormLabel = styled.label`
  font-size: 16px;
  font-family: Roboto;
  color: #423956;
  letter-spacing: 1px;
  font-weight: bold;
`;
const FormField = styled.div`
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
const FormFields = styled.div`
  margin: auto;
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
const Input = styled.input`
  font-size: 16px;
  font-family: Roboto;
  color: #737373;
  letter-spacing: 1px;
  background-color: #fafafc;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #c8c8e7;
  width: calc(100% - 40px);
`;
const TextArea = styled.textarea`
  font-size: 16px;
  font-family: Roboto;
  color: #737373;
  letter-spacing: 1px;
  background-color: #fafafc;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #c8c8e7;
  width: calc(100% - 40px);
  height: 200px;
  resize: none;
`;
const ButtonBlock = styled.div`
  margin: auto;
  width: 100%;
  button {
    width: 100%;
  }
`;

export const Contacts: FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const form = useRef(null);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      form.current &&
      process.env.REACT_APP_EMAIL_PUBLIC_ID &&
      process.env.REACT_APP_EMAIL_TEMPLATE_ID &&
      process.env.REACT_APP_EMAIL_SERVICE_ID
    ) {
      setLoading(true);
      const target = event.target as typeof event.target & {
        user_name: { value: string };
        message: { value: string };
        user_email: { value: string };
      };
      const message = target.message.value;
      const user_name = target.user_name.value;
      const user_email = target.user_email.value;

      emailjs
        .send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_EMAIL_TEMPLATE_ID,
          {
            user_name: user_name,
            message: message,
            user_email: user_email
          },
          process.env.REACT_APP_EMAIL_PUBLIC_ID
        )
        .then(
          () => {
            toast.success('The message has been sent', {
              position: 'bottom-left',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: 'light'
            });

            setLoading(false);
          },
          error => {
            toast.error(error.text, {
              position: 'bottom-left',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: 'light'
            });
            setLoading(false);
          }
        );
    }
    const htmlForm = event.currentTarget;
    htmlForm.reset();
  };

  return (
    <>
      <SectionBlock>
        <CardContent isMobile={isTabletOrMobile}>
          <FormInfo>
            <SectionTitleModfied>Get In Touch With Me</SectionTitleModfied>
            <SimpleText>Thanks for taking the time to reach out. How can i help you today</SimpleText>
            <ContactSpacer />
            <ContactInfo>
              <ImgPlacholderClickable
                onClick={e => {
                  window.location.href = 'mailto:evaldas.laureckas@gmail.com';
                  e.preventDefault();
                }}
              >
                <Img src={MessageSvg} alt="Email Address" />
              </ImgPlacholderClickable>
              <TextBlock>
                <SimpleTextModified>Email Address</SimpleTextModified>
                <SimpleText>evaldas.laureckas@gmail.com</SimpleText>
              </TextBlock>
            </ContactInfo>
            <Separator />
            <ContactInfo>
              <ImgPlacholder>
                <Img src={LocationSvg} alt="Location" />
              </ImgPlacholder>
              <TextBlock>
                <SimpleTextModified>Location</SimpleTextModified>
                <SimpleText>Klaipėda, Lithuania</SimpleText>
              </TextBlock>
            </ContactInfo>
          </FormInfo>
          <Form onSubmit={handleSubmit} ref={form} isMobile={isTabletOrMobile}>
            <FormFields>
              <FormField>
                <FormLabel>Email Address</FormLabel>
                <Input type="email" placeholder="Email Address" required name="user_email" />
              </FormField>
              <FormField>
                <FormLabel>Name</FormLabel>
                <Input type="text" placeholder="Name" required name="user_name" />
              </FormField>
              <FormField>
                <FormLabel>Write Message</FormLabel>
                <TextArea placeholder="Write Description" required name="message" />
              </FormField>
              <ButtonBlock>
                <Button label={'Send Message'} isSubmit={true} colorType={ColorType.secondary} isLoading={isLoading} />
              </ButtonBlock>
            </FormFields>
          </Form>
        </CardContent>
      </SectionBlock>
    </>
  );
};
